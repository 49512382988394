import { writable } from 'svelte/store';


// default: DAA
// 1: DAA Technikum
// 2: DAA
// 4: HFH


export const userText = writable({

    "firstName": {
        "name": {
            "default": "Vorname",
        },
        "text": {
            "default": "",
        }
    },
    "lastName": {
        "name": {
            "default": "Nachname",
        },
        "text": {
            "default": "",
        }
    },
    "title": {
        "name": {
            "default": "Titel",
        },
        "text": {
            "default": "",
        }
    },
    "eMail": {
        "name": {
            "default": "E-Mail",
        },
        "text": {
            "default": "",
        }
    },
    "explainEMail": {
        "name": {
            "default": "Hinweis E-Mail",
        },
        "text": {
            "default": "Bitte geben Sie eine E-Mail-Adresse an, auf die nur Sie Zugriff haben. Wir nutzen diese E-Mail-Adresse, um Ihnen Ihre Abrechnung sicher und direkt zuzustellen:",
        }
    },
    "phoneNumber": {
        "name": {
            "default": "Telefon",
        },
        "text": {
            "default": "",
        }
    },
    "explainPhoneNumber": {
        "name": {
            "default": "Hinweis Telefonnummer",
        },
        "text": {
            "default": "Und gegebenenfalls auch Ihre Telefonnummer (Festnetz oder Mobil):",
        }
    },



    "countryName": {
        "name": {
            "default": "Land",
        },
        "text": {
            "default": "",
        }
    },
    "location": {
        "name": {
            "default": "Ort",
        },
        "text": {
            "default": "",
        }
    },
    "street": {
        "name": {
            "default": "Straße",
        },
        "text": {
            "default": "",
        }
    },
    "streetNumber": {
        "name": {
            "default": "Hausnummer",
        },
        "text": {
            "default": "",
        }
    },
    "additionalAddressLine": {
        "name": {
            "default": "Anschriftenzusatz",
        },
        "text": {
            "default": "",
        }
    },



    "nationality": {
        "name": {
            "default": "Staatsangehörigkeit",
        },
        "text": {
            "default": "",
        }
    },
    "gender": {
        "name": {
            "default": "Geschlecht",
        },
        "text": {
            "default": "",
        }
    },



    "explainGraduation": {
        "name": {
            "default": "Hinweis Ausbildung",
        },
        "text": {
            "default": "Diese Daten benötigen wir für statistische Zwecke der Sozialversicherung:",
        }
    },
    "schoolGraduation": {
        "name": {
            "default": "Schulabschluss:",
        },
        "text": {
            "default": "",
        }
    },
    "jobGraduation": {
        "name": {
            "default": "Ausbildungsabschluss",
        },
        "text": {
            "default": "",
        }
    },


    "IBAN": {
        "name": {
            "default": "IBAN (DE, AT oder CH mit angeben)",
        },
        "text": {
            "default": "",
        }
    },
    "BIC": {
        "name": {
            "default": "BIC",
        },
        "text": {
            "default": "",
        }
    },
    "foreignIBAN": {
        "name": {
            "default": "Ausländische IBAN",
        },
        "text": {
            "default": "",
        }
    },
    "differentAccountOwner": {
        "name": {
            "default": "Abweichender Kontoinhaber",
        },
        "text": {
            "default": "",
        }
    },




    "taxId": {
        "name": {
            "default": "Steuer-Identifikationsnummer",
        },
        "text": {
            "default": "",
        }
    },
    "additionalOccupation": {
        "name": {
            "default": "Die Tätigkeit für die DAA ist eine Nebenbeschäftigung",
            1: "Die Tätigkeit für das DAA-Technikum ist eine Nebenbeschäftigung",
            2: "Die Tätigkeit für die DAA ist eine Nebenbeschäftigung",
            4: "Die Tätigkeit für die HFH ist eine Nebenbeschäftigung",
        },
        "text": {
            "default": "",
        }
    },
    "taxClass":{
        "name": {
            "default": "Steuerklasse der Tätigkeit für die DAA",
            1: "Steuerklasse der Tätigkeit für das DAA-Technikum",
            2: "Steuerklasse der Tätigkeit für die DAA",
            4: "Steuerklasse der Tätigkeit für die HFH",
        },
        "text": {
            "default": "",
        }
    },




    "explainSSN": {
        "name": {
            "default": "Erläuterung zu SSN",
        },
        "text": {
            "default": 'Wir benötigen Ihre Sozialversicherungsnummer (SVN). Wenn Sie diese Nummer nicht kennen und nicht herausfinden können oder ein ausländische Nummer verwenden, klicken Sie bitte auf "Nein".',
        }
    },
    "SSNisKnown": {
        "name": {
            "default": "SVN ist bekannt",
        },
        "text": {
            "default": "SVN ist bekannt",
        }
    },
    "SSN": {
        "name": {
            "default": "Sozialversicherungsnummer",
        },
        "text": {
            "default": "Da die Sozialversicherungsnummer nicht bekannt ist, oder Sie eine ausländische Nummer verwenden, bitte Folgendes angeben:",
        }
    },
    "birthDate": {
        "name": {
            "default": "Geburtsdatum",
        },
        "text": {
            "default": "Geburtsdatum",
        }
    },
    "birthName": {
        "name": {
            "default": "Geburtsname",
        },
        "text": {
            "default": "Geburtsname",
        }
    },
    "birthLocation": {
        "name": {
            "default": "Geburtsort",
        },
        "text": {
            "default": "Geburtsort",
        }
    },
    "birthCountry": {
        "name": {
            "default": "Geburtsland",
        },
        "text": {
            "default": "Geburtsland",
        }
    },

    "explainSSNother": {
        "name": {
            "default": "Hinweis 2 zu SSN",
        },
        "text": {
            "default": "Sind unter dieser Sozialversicherungsnummer bereits andere Beschäftigungsverhältnisse und/oder Rentenbezüge gemeldet?",
        }
    },

    "SSNother": {
        "name": {
            "default": "",
        },
        "text": {
            "default": "",
        }
    },


    "healthInsuranceContainer": {
        "name": {
            "default": "Art der Versicherung",
        },
        "text": {
            "default": "",
        }
    },
    "healthInsuranceType": {
        "name": {
            "default": "Ich bin versichert",
        },
        "text": {
            "default": "Ich bin versichert",
        }
    },
    "healthInsuranceCompany": {
        "name": {
            "default": "Name der Krankenkasse",
        },
        "text": {
            "default": "Name der Krankenkasse",
        }
    },
    "healthInsuranceCompanyPrivate": {
        "name": {
            "default": "Name der Beitragseinzugskasse",
        },
        "text": {
            "default": "Name der Beitragseinzugskasse",
        }
    },
   
    "hasPensionContributionExplanation": {
        "name": {
            "default": "Erläuterung zu SSN",
        },
        "text": {
            "default": 'Werden für Sie Rentenversicherungsbeiträge an die Deutsche Rentenversicherung abgeführt? Ja: z.B. weil ich Angestellte/r bin oder Nein: z.B. weil ich Rentner/in oder Beamter/Beamtin bin:',
        }
    },
    "hasPensionContributionExplanation2": {
        "name": {
            "default": "Erläuterung zu SSN",
        },
        "text": {
            "default": 'Die Rentenversicherungsbeiträge werden auch bei privat Versicherten an eine gesetzliche Krankenkasse abgeführt. Diese finden Sie z.B. in Ihrer jährlichen Bescheinigung über die Sozialabgaben, sofern Sie einen Arbeitgeber haben. Bitte geben Sie hier diese Krankenkasse an:',
        }
    },



    "explainChildren": {
        "name": {
            "default": "Hinweis zu Kindern",
        },
        "text": {
            "default": "Bitte geben Sie für die Berechnung des Pflegeversicherungsbeitrags an, ob Sie Kinder haben.",
        }
    },

    "children": {
        "name": {
            "default": "Ich habe Kinder",
        },
        "text": {
            "default": "Ich habe Kinder",
        }
    },

    "childNumberUnder25":  {
        "name": {
            "default": "Anzahl Kinder unter 25 Jahren",
        },
        "text": {
            "default": "Anzahl Kinder unter 25 Jahren",
        }
    },



    "Mini": {
        "label": {
            "default": "Ich habe einen Minijob (in einem anderen Unternehmen als der DAA).",
            1: "Ich habe einen Minijob (in einem anderen Unternehmen als dem DAA-Technikum).",
            2: "Ich habe einen Minijob (in einem anderen Unternehmen als der DAA).",
            4: "Ich habe einen Minijob (in einem anderen Unternehmen als der HFH).",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
                {name:"Ja", value:"Mini_A"},
                {name:"Nein", value:"Mini_B"},
            ],
        }
    },
    "Mini_A": {
        "label": {
            "default": "Haben Sie zusätzlich zum Minijob auch eine Beschäftigung, bei der Sie sozialversicherungspflichtig beschäftigt sind (also für mehr als 538 € im Monatsmittel)?",
        },
        "optionalText": {
            "default": "Hinweis: wenn Sie nur als Beamter/Beamtin tätig sind, bitte „Nein“ ankreuzen, da Beamte nicht sozialversicherungspflichtig beschäftigt sind.",
        },
        "options": {
            "default": [
                {name:"Ja", value:"Mini_A_A"},
                {name:"Nein", value:"Mini_A_B"},
            ],
        }
    },
    "Mini_A_A": {
        "label": {
            "default": "Ja.",
        },
        "optionalText": {
            "default": "In diesem Fall ist nur ein Minijob möglich, den haben Sie schon. Wir finden einen anderen Weg, der nicht analog zu einem Minijob ist.",
        },
        "options": {
            "default": [
            ],
        }
    },
    "Mini_A_B": {
        "label": {
            "default": "Nein.",
        },
        "optionalText": {
            "default": "Geben Sie bitte das monatliche Einkommen ihres Minijobs an, damit wir prüfen können, ob die Tätigkeit bei uns auch noch als Minijob möglich ist.",
        },
        "options": {
            "default": [
            ],
        }
    },
    "minijobIncomeMonth": {
        "name": {
            "default": "Betrag in € (ganze Zahl)",
        },
        "text": {
            "default": "",
        }
    },


    "AddQ": {
        "label": {
            "default": "Dozententätigkeit",
        },
        "optionalText": {
            "default": "Die DAA kann die Übungsleiterpauschale (bis max. 3000 € pro Jahr) nutzen, damit Honorare aus Dozententätigkeit oder Korrekturen (nicht aber Studienortleitung, Prüfungsbetreuung etc.) von der Sozialversicherung und der Abführung von Lohnsteuer befreit sind. Uns wurde empfohlen, dies erst ab Januar 2025 zu nutzen, um eine korrekte Abwicklung sicherzustellen, die bei einem innerjährlichen Wechsel schwieriger ist. Möchten Sie, soweit sie als Dozent tätig sind, uns bitten, für Unterrichtshonorare ab 2025 soweit möglich die Übungsleiterpauschale zu nutzen?",
            1: "Das DAA-Technikum kann die Übungsleiterpauschale (bis max. 3000 € pro Jahr) nutzen, damit Honorare aus Dozententätigkeit oder Korrekturen (nicht aber Studienortleitung, Prüfungsbetreuung etc.) von der Sozialversicherung und der Abführung von Lohnsteuer befreit sind. Dem DAA-Technikum wurde empfohlen, dies erst ab Januar 2025 zu nutzen, um eine korrekte Abwicklung sicherzustellen, die bei einem innerjährlichen Wechsel schwieriger ist. Möchten Sie, soweit sie als Dozent tätig sind, das DAA-Technikum bitten, für Unterrichtshonorare ab 2025 soweit möglich die Übungsleiterpauschale zu nutzen?",
            2: "Die DAA kann die Übungsleiterpauschale (bis max. 3000 € pro Jahr) nutzen, damit Honorare aus Dozententätigkeit oder Korrekturen (nicht aber Studienortleitung, Prüfungsbetreuung etc.) von der Sozialversicherung und der Abführung von Lohnsteuer befreit sind. Der DAA wurde empfohlen, dies erst ab Januar 2025 zu nutzen, um eine korrekte Abwicklung sicherzustellen, die bei einem innerjährlichen Wechsel schwieriger ist. Möchten Sie, soweit sie als Dozent tätig sind, die DAA bitten, für Unterrichtshonorare ab 2025 soweit möglich die Übungsleiterpauschale zu nutzen?",
            4: "Die HFH kann die Übungsleiterpauschale (bis max. 3000 € pro Jahr) nutzen, damit Honorare aus Dozententätigkeit oder Korrekturen (nicht aber Studienortleitung, Prüfungsbetreuung etc.) von der Sozialversicherung und der Abführung von Lohnsteuer befreit sind. Der HFH wurde empfohlen, dies erst ab Januar 2025 zu nutzen, um eine korrekte Abwicklung sicherzustellen, die bei einem innerjährlichen Wechsel schwieriger ist. Möchten Sie, soweit sie als Dozent tätig sind, die HFH bitten, für Unterrichtshonorare ab 2025 soweit möglich die Übungsleiterpauschale zu nutzen?",
        
        },
        "options": {
            "default": [
                {name:"Ja, ich möchte ab 2025 die Übungsleiterpauschale in voller Höhe der DAA zur Verfügung stellen, um meine Honore soweit möglich von Sozialabgaben und Steuern zu befreien.", value:"AddQ_A"},
                {name:"Ja, bis zu einem maximalen Betrag für 2025, weil ich den übrigen Betrag für andere Übungsleitertätigkeiten nutze.", value:"AddQ_B"},
                {name:"Nein, z.B. weil ich die Übungsleiterpauschale für andere Tätigkeiten nutze.", value:"AddQ_C"},
            ],
            1: [
                {name:"Ja, ich möchte ab 2025 die Übungsleiterpauschale in voller Höhe dem DAA-Technikum zur Verfügung stellen, um meine Honore soweit möglich von Sozialabgaben und Steuern zu befreien.", value:"AddQ_A"},
                {name:"Ja, bis zu einem maximalen Betrag für 2025, weil ich den übrigen Betrag für andere Übungsleitertätigkeiten nutze.", value:"AddQ_B"},
                {name:"Nein, z.B. weil ich die Übungsleiterpauschale für andere Tätigkeiten nutze.", value:"AddQ_C"},
            ],
            2: [
                {name:"Ja, ich möchte ab 2025 die Übungsleiterpauschale in voller Höhe der DAA zur Verfügung stellen, um meine Honore soweit möglich von Sozialabgaben und Steuern zu befreien.", value:"AddQ_A"},
                {name:"Ja, bis zu einem maximalen Betrag für 2025, weil ich den übrigen Betrag für andere Übungsleitertätigkeiten nutze.", value:"AddQ_B"},
                {name:"Nein, z.B. weil ich die Übungsleiterpauschale für andere Tätigkeiten nutze.", value:"AddQ_C"},
            ],
            4: [
                {name:"Ja, ich möchte ab 2025 die Übungsleiterpauschale in voller Höhe der HFH zur Verfügung stellen, um meine Honore soweit möglich von Sozialabgaben und Steuern zu befreien.", value:"AddQ_A"},
                {name:"Ja, bis zu einem maximalen Betrag für 2025, weil ich den übrigen Betrag für andere Übungsleitertätigkeiten nutze.", value:"AddQ_B"},
                {name:"Nein, z.B. weil ich die Übungsleiterpauschale für andere Tätigkeiten nutze.", value:"AddQ_C"},
            ],
            
        }
    },

    "AddQ_B":{
        "label": {
            "default": "Maximaler Betrag für 2025",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
            ],
        }
    },
    "UeLeiterPauschaleMax": {
        "name": {
            "default": "Betrag in € (ganze Zahl)",
        },
        "text": {
            "default": "",
        }
    },
    "UeLeiterHoursPerWeek": {
        "name": {
            "default": "Stunden pro Woche",
        },
        "text": {
            "default": "",
        }
    },

    "Versorgungswerk": {
        "name": {
            "default": "Name des Versorgungswerks",
        },
        "label": {
            "default": "Name des Versorgungswerks",
        },
        "text": {
            "default": "Name des Versorgungswerks",
        }
    },

    "VWMitgliednummer": {
        "name": {
            "default": "Meine Mitgliednummer",
        },
        "label": {
            "default": "Meine Mitgliednummer",
        },
        "text": {
            "default": "Meine Mitgliednummer",
        }
    },

    "explainBefreiDRV": {
        "name": {
            "default": "Befreiung Beitrag DRV",
        },
        "text": {
            "default": "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätgkeit bei der DAA?",
            1: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätgkeit beim DAA-Technikum?",
            2: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätgkeit bei der DAA?",
            4: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätgkeit bei der HFH?",
        }
    },

    "UeLeiterHoursPerWeekExplanation": {
        "name": {
            "default": "Befreiung Beitrag DRV",
        },
        "text": {
            "default": "Weitere Frage: Für die eventuelle Anwendung der Übungsleiterpauschale für die Nebentätigkeit geben Sie uns bitte an, wie viele Wochenstunden Sie in Ihrem Hauptberuf arbeiten (ohne Hauptberuf bitte 0 eintragen):",
        }
    }
    ,



    "P": {
        "label": {
            "default": "Meine berufliche Situation",
        },
        "optionalText": {
            "default": "Bitte gehen Sie die folgende Liste von oben nach unten durch und kreuzen Sie die erste Zeile an, die auf Sie zutrifft, auch wenn danach noch weitere Zeilen auf Sie zutreffen würden.",
        },
        "options": {
            "default": [
                {name:"Ich bin Beamtin/er (aktiv oder in Pension).", value:"P_A"},
                {name:"Ich bin Rentner/in (nicht gemeint: Hinterbliebenenrente).", value:"P_B"},
                {name:"Ich habe einen Hauptberuf neben der Tätigkeit bei der DAA.", value:"P_C"},
                {name:"Ich bin Student/in.", value:"P_D"},
                {name:"Ich habe sonst keine Beschäftigung.", value:"P_E"},
            ],
            1: [
                {name:"Ich bin Beamtin/er (aktiv oder in Pension).", value:"P_A"},
                {name:"Ich bin Rentner/in (nicht gemeint: Hinterbliebenenrente).", value:"P_B"},
                {name:"Ich habe einen Hauptberuf neben der Tätigkeit beim DAA-Technikum.", value:"P_C"},
                {name:"Ich bin Student/in.", value:"P_D"},
                {name:"Ich habe sonst keine Beschäftigung.", value:"P_E"},
            ],
            2: [
                {name:"Ich bin Beamtin/er (aktiv oder in Pension).", value:"P_A"},
                {name:"Ich bin Rentner/in (nicht gemeint: Hinterbliebenenrente).", value:"P_B"},
                {name:"Ich habe einen Hauptberuf neben der Tätigkeit bei der DAA.", value:"P_C"},
                {name:"Ich bin Student/in.", value:"P_D"},
                {name:"Ich habe sonst keine Beschäftigung.", value:"P_E"},
            ],
            4: [
                {name:"Ich bin Beamtin/er (aktiv oder in Pension).", value:"P_A"},
                {name:"Ich bin Rentner/in (nicht gemeint: Hinterbliebenenrente).", value:"P_B"},
                {name:"Ich habe einen Hauptberuf neben der Tätigkeit bei der HFH.", value:"P_C"},
                {name:"Ich bin Student/in.", value:"P_D"},
                {name:"Ich habe sonst keine Beschäftigung.", value:"P_E"},
            ],
        }
    },
    "P_A": {
        "label": {
            "default": "Ich bin pensionierte(r) Beamtin/er.",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
                {name:"Ja, ich bin in Pension.", value:"P_A_A"},
                {name:"Nein, in bin noch aktive/r Beamtin/er.", value:"P_A_B"}
            ],
        }
    },
    "P_B": {
        "label": {
            "default": "Ich bin Rentner/in",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
                {name:"Normalfall: Ich beziehe die gesamte, mir zustehende Rente (ggf. mit Abschlag bei vorzeitigem Bezug) oder Versorgungsbezüge.", value:"P_B_A"},
                {name:"Sonderfall: Teilrente: ich habe meine Rentenversicherung bzw. mein Versorgungswerk gebeten, nur einen Teil der Rente auszuzahlen, damit ich später mehr Rente bekomme.", value:"P_B_B"},
                {name:"Ich beziehe eine Erwerbsminderungsrente (volle Erwerbsminderung).", value:"P_B_C"},
                {name:"Ich beziehe eine Berufsunfähigkeitsrente (teilweise Erwerbsminderung).", value:"P_B_D"},
                {name:"Ich bin im Rentenalter, erhalte jedoch keine Rente.", value:"P_B_E"},
            ],
        }
    },
    "P_B_A": {
        "label": {
            "default": "Rente - Normalfall",
        },
        "optionalText": {
            "default": "Wo sind Sie versichert?",
        },
        "options": {
            "default": [
                {name:"Ich bin bei der Deutschen Rentenversicherung versichert.", value:"P_B_A_A"},
                {name:"In einem Versorgungswerk (z.B. für Ärzte, Anwälte etc.).", value:"P_B_A_B"},
            ],
        }
    },
    "P_B_A_A": {
        "label": {
            "default": "Ja, ich bin bei der Deutschen Rentenversicherung versichert.",
        },
        "optionalText": {
            "default": "Bitte senden Sie uns nach Möglichkeit ihren Rentenbescheid, damit wir die richtige Form der Sozialversicherung prüfen können.",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_B_A_B": {
        "label": {
            "default": "Ich bin in einem Versorgungswerk versichert.",
        },
        "optionalText": {
            "default": "Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_B_B": {
        "label": {
            "default": "Sonderfall Teilrente",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
                {name:"Ich bin bei der Deutschen Rentenversicherung versichert.", value:"P_B_B_A"},
                {name:"In einem Versorgungswerk (z.B. für Ärzte, Anwälte etc.).", value:"P_B_B_B"},
            ],
        }
    },
    "P_B_B_A": {
        "label": {
            "default": "Ja, ich bin bei der Deutschen Rentenversicherung versichert.",
        },
        "optionalText": {
            "default": "Bitte senden Sie uns nach Möglichkeit ihren Rentenbescheid, damit wir die richtige Form der Sozialversicherung prüfen können.",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_B_B_B": {
        "label": {
            "default": "Ich bin in einem Versorgungswerk versichert.",
        },
        "optionalText": {
            "default": "Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_B_C": {
        "label": {
            "default": "Ich beziehe eine Erwerbsminderungsrente (volle Erwerbsminderung).",
        },
        "optionalText": {
            "default": "Wo sind Sie versichert?",
        },
        "options": {
            "default": [
                {name:"Ich bin bei der Deutschen Rentenversicherung versichert.", value:"P_B_C_A"},
                {name:"In einem Versorgungswerk (z.B. für Ärzte, Anwälte etc.).", value:"P_B_C_B"},
            ],
        }
    },
    "P_B_C_A": {
        "label": {
            "default": "Ja, ich bin bei der Deutschen Rentenversicherung versichert.",
        },
        "optionalText": {
            "default": "Bitte senden Sie uns nach Möglichkeit Ihren Rentenbescheid, damit wir die richtige Form der Sozialversicherung prüfen können.",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_B_C_B": {
        "label": {
            "default": "Ich bin in einem Versorgungswerk versichert.",
        },
        "optionalText": {
            "default": "Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        },
        "options": {
            "default": [
            ],
        }
    },

    "P_B_D": {
        "label": {
            "default": "Ich beziehe eine Berufsunfähigkeitsrente (teilweise Erwerbsminderung).",
        },
        "optionalText": {
            "default": "Wo sind Sie versichert?",
        },
        "options": {
            "default": [
                {name:"Ich bin bei der Deutschen Rentenversicherung versichert", value:"P_B_D_A"},
                {name:"In einem Versorgungswerk (z.B. für Ärzte, Anwälte etc.)", value:"P_B_D_B"},
            ],
        }
    },
    "P_B_D_A":  {
        "label": {
            "default": "Ja, ich bin bei der Deutschen Rentenversicherung versichert.",
        },
        "optionalText": {
            "default": "Bitte senden Sie uns nach Möglichkeit ihren Rentenbescheid, damit wir die richtige Form der Sozialversicherung prüfen können.",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_B_D_B":  {
        "label": {
            "default": "Ich bin in einem Versorgungswerk versichert.",
        },
        "optionalText": {
            "default": "Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_B_E":  {
        "label": {
            "default": "Ich bin im Rentenalter, erhalte jedoch keine Rente.",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
                {name:"Ich habe keinen Rentenanspruch", value:"P_B_E_A"},
                {name:"Ich erfüllte die  Voraussetzungen, nehme aber keine Rente in Anspruch.", value:"P_B_E_B"},
            ],
        }
    },
    "P_C":  {
        "label": {
            "default": "Hauptberuf neben der Tätigkeit bei der DAA",
            1: "Hauptberuf neben der Tätigkeit beim DAA-Technikum",
            2: "Hauptberuf neben der Tätigkeit bei der DAA",
            4: "Hauptberuf neben der Tätigkeit bei der HFH",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
                {name:"Ich bin bei der Deutschen Rentenversicherung versichert.", value:"P_C_A"},
                {name:"Ich bin in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert Bitte geben Sie hier das Versorgungswerk an:", value:"P_C_B"},
                {name:"Ich bin nicht rentenversicherungspflichtig (z.B. hauptberuflich selbständig).", value:"P_C_C"},
            ],
        }
    },
    "P_C_B":  {
        "label": {
            "default": "Versorgungswerk",
        },
        "optionalText": {
            "default": "Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        },
        "options": {
            "default": [
            ],
        }
    },
    "BefreiungsbescheidDRV_P_C_B": {
        "name": {
            "default": "BefreiungsbescheidDRV",
        },
        "label": {
            "default": "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit bei der DAA?",
            1: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit beim DAA-Technikum?",
            2: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit bei der DAA?",
            4: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit bei der HFH?",
        },
        "optionalText": {
            "default": "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit bei der DAA?",
            1: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit beim DAA-Technikum?",
            2: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit bei der DAA?",
            4: "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit bei der HFH?",
        },
        "options": {
            "default": [
                {id:"1", groupName:"yesNo", label:"Ja",value:"P_C_B_A"},
                {id:"0", groupName:"yesNo", label:"Nein",value:"P_C_B_B"},
            ],
        }
    },
    "P_C_B_A":  {
        "label": {
            "default": "P_C_B_A_Label",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_C_B_B":  {
        "label": {
            "default": "P_C_B_B_Label",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_C_C":  {
        "label": {
            "default": "Nicht rentenversicherungspflichtig",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_D":  {
        "label": {
            "default": "Ich habe eine gültige Immatrikulationsbescheinigung.",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
                {name:"Ja", value:"P_D_A"},
                {name:"Nein", value:"P_D_B"},
            ],
        }
    },
    "P_D_A":  {
        "label": {
            "default": "Immatrikulationsbescheinigung zusenden.",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_A_A":  {
        "label": {
            "default": "Meine Pension",
        },
        "optionalText": {
            "default": "Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit bei der DAA erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
            1: "Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit beim DAA-Technikum erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
            2: "Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit bei der DAA erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
            4: "Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit bei der HFH erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
        },
        "options": {
            "default": [
                {name:"Ja, ich habe den Bescheid", value:"P_A_A_A"},
                {name:"Nein", value:"P_A_A_B"}
            ],
        }
    },
    "P_A_A_A":  {
        "label": {
            "default": "Der Bescheid ist vorhanden. Bitte zusenden.",
        },
        "optionalText": {
            "default": "",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_A_A_B":  {
        "label": {
            "default": "Bescheid noch nicht vorhanden. Wenn möglich, bitte nachreichen.",
        },
        "optionalText": {
            "default": "Es wäre für uns von Vorteil, wenn Sie solch einen Bescheid beantragen könnten. Wir helfen Ihnen gern dabei.",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_A_B":  {
        "label": {
            "default": "Meine Pension bzw. mein Pensionsanspruch",
        },
        "optionalText": {
            "default": "Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit bei der DAA erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
            1: "Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit beim DAA-Technikum erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
            2: "Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit bei der DAA erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
            4: "Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit bei der HFH erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
        },
        "options": {
            "default": [
                {name:"Ja", value:"P_A_B_A"},
                {name:"Nein", value:"P_A_B_B"}
            ],
        }
    },
    "P_A_B_A":  {
        "label": {
            "default": "Der Bescheid ist vorhanden. Bitte zusenden.",
        },
        "optionalText": {
            "default": "Den Bescheid bitte per E-Mail an an -eMailAdresse- senden.",
            1: "Den Bescheid bitte per E-Mail an dozenten@daa-technikum.de senden.",
            2: "Den Bescheid bitte per E-Mail an -eMailAdresse- senden.",
            4: "Den Bescheid bitte per E-Mail an -eMailAdresse- senden.",
        },
        "options": {
            "default": [
            ],
        }
    },
    "P_A_B_B":  {
        "label": {
            "default": "Bescheid nicht vorhanden.",
        },
        "optionalText": {
            "default": "Es wäre für uns von Vorteil, wenn Sie solch einen Bescheid beantragen könnten. Wir helfen Ihnen gern dabei.",
        },
        "options": {
            "default": [
            ],
        }
    },
   
    "adminFirstName": {
        "name": {
            "default": "Vorname",
        },
        "text": {
            "default": "Vorname",
        }
    },

    "adminLastName": {
        "name": {
            "default": "Nachname",
        },
        "text": {
            "default": "Nachname",
        }
    },

    "adminUserName": {
        "name": {
            "default": "Username",
        },
        "text": {
            "default": "Username",
        }
    },

    "adminInitialPWD": {
        "name": {
            "default": "Passwort (min 8)",
        },
        "text": {
            "default": "Passwort (min 8)",
        }
    },


    "adminCompany": {
        "name": {
            "default": "Firma",
        },
        "text": {
            "default": "Firma",
        }
    },
    "adminEMail": {
        "name": {
            "default": "EMail",
        },
        "text": {
            "default": "EMail",
        }
    },

});

export function getItemText (textObj, strKey, strSubKey, groupId ) {

    if (!textObj) return "TextObj argument missing";
    if (!strKey) return "strKey argument missing";
    if (!strSubKey) return "strSubKey argument missing";
   
    let gId;
    let checkGroupId = parseInt(groupId);
    (checkGroupId > 0) ? gId = checkGroupId : "default";

    // console.log("userText look for:", strKey, strSubKey, groupId);

    // only default available:
    let exists = textObj[strKey][strSubKey][gId];
    if ( !exists || typeof exists === "undefined") {
        return textObj[strKey][strSubKey]["default"];
    }
    
    // groupId is used:
    return textObj[strKey][strSubKey][gId];
}

