import { writable } from 'svelte/store';

export const helptext = writable({

    "firstName": `<div class="daa-help-box-headline">Vorname</div>
                  <div class="daa-help-box-text">Ihr Vorname</div`,

    "eMail": `<div class="daa-help-box-headline">EMail Hinweis</div>
                  <div class="daa-help-box-text">Erklärender Text zur EMail</div`,

    "somethingDifferent": `<div class="daa-help-box-headline">Erläuterung</div>
                  <div class="daa-help-box-text">Sie sind also nicht bei einem Versorgungswerk</div>`,

    "taxId": `<div class="daa-help-box-headline">Steuer-Identifikationsnummer</div>
            <div class="daa-help-box-text">Diese Nummer besteht aus 11 Zeichen.</div>
            <div class="daa-help-box-text">Sie können sie z.B. auf Ihrer Lohnsteuerkarte oder Ihrem Einkommensteuerbescheid finden.</div>`,
    
    "SSN": `<div class="daa-help-box-headline">Sozialversicherungsnummer</div>
            <div class="daa-help-box-text">Die Sozialversicherungsnummer besteht aus 12 Zahlen und Buchstaben. An den Stellen 3 bis 8 befindet sich Ihr Geburtsdatum (TTMMJJ).</div>
            <div class="daa-help-box-text">Sie können sie z.B. auf Ihrem Sozialversicherungsausweis oder Schreiben der Rentenkasse finden.</div>`,

    "IBAN": `<div class="daa-help-box-text">Geben Sie die Länderkennung (z. B. DE) mit ein.</div>
            <div class="daa-help-box-text">Wenn Sie keine IBAN haben, aktivieren Sie bitte die Checkbox (Häkchen).</div>
            <div class="daa-help-box-text">Wenn Ihre IBAN nicht mit DE, AT oder CH beginnt, aktivieren Sie die Checkbox und verwenden Sie das Feld "Ausländische IBAN".</div>`,

    "SSNother": `<div class="daa-help-box-text">Diese Information ist nötig, um Ihre Sozialversicherungsbeiträge korrekt zu erfassen.</div>
                  <div class="daa-help-box-text">Bei mehreren sozialversicherungspflichtigen Beschäftigungen erfolgt der Ausgleich automatisch durch den Sozialversicherungsträger. Sie müssen nichts tun.</div`,
    
    
    
});
