import { writable } from 'svelte/store';

export const pages = writable({

    "page1": {
        "id":1,
        "name":"Seite 1",
        "panels": ["panel_0", "panel_1", "panel_3", "panel_7"],
    },
    "page2": {
        "id":2,
        "name":"Seite 2",
        "panels": ["panel_4", "panel_5"],
    },
    "page2old": {
        "id":2,
        "name":"Seite 2",
        "panels": ["panel_4", "panel_5"],
    },
    "page3": {
        "id":3,
        "name":"Seite 3",
        "panels": ["panel_6", "panel_22", "panel_23"],
    },

    "page4": {
        "id":4,
        "name":"Seite 4",
        "panels": ["panel_20"],
    },
    "page5": {
        "id":5,
        "name":"Seite 5",
        "panels": ["panel_21", "panel_26", "panel_24"],
    },
})

export const panels = writable({

    "panel_0": {
        "name": "Mitarbeiterinformationen",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-label-w300",
        "required": false,
        "fields": ["firstName", "lastName", "title", "empty", "explainEMail", "eMail", "explainPhoneNumber", "phoneNumber"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_1": {
        "name": "Adresse",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-label-w300",
        "required": false,
        "fields": ["countryName", "location", "street", "additionalAddressLine"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_3": {
        "name": "Person",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-label-w300",
        "required": false,
        "fields": ["nationality", "gender"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_7": {
        "name": "Ausbildung",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-label-w300",
        "required": false,
        "fields": ["explainGraduation", "schoolGraduation", "jobGraduation"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    }, 

    "panel_4": {
        "name": "Bankverbindung",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-label-w300",
        "required": false,
        "fields": ["IBAN", "BIC", "foreignIBAN", "differentAccountOwner"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_5": {
        "name": "Steuerinformationen",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-label-extreme-text",
        "required": false,
        "fields": ["taxId",  "taxClassContainer"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },


    "panel_6": {
        "name": "Sozialversicherungsnummer",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-label-w300",
        "required": false,
        "fields": ["SSNContainer", "explainSSNother", "SSNother"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_19": {
        "name": "Mein Geburtstag",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-radio-w300",
        "required": false,
        "fields": ["birthDate"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_20": {
        "name": "Beruf",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-radio-w300",
        "required": false,
        "fields": ["P"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_21": {
        "name": "Minijob",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-radio-w300",
        "required": false,
        "fields": ["Mini"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_22": {
        "name": "Art der Krankenversicherung",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-radio-w300",
        "required": false,
        "fields": ["healthInsuranceContainer"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_23": {
        "name": "Kinder",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-radio-w300",
        "required": false,
        "fields": ["childrenContainer"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_24": {
        "name": "Weitere Fragen für alle",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-radio-w300",
        "required": false,
        "fields": ["AddQ"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    "panel_26": {
        "name": "Umfang des Hauptberufs",
        "panelClass": "daa-panel-padding-r100",
        "labelClass": "daa-label-w130",
        "required": false,
        "fields": ["UeLeiterHoursPerWeekExplanation","UeLeiterHoursPerWeek"],
        "useHead": true,
        "useHelp": false,
        "helpText": "",
    },

    

});



export const fields = writable({

    "empty": {
        "name": "emptyRow",
        "type": "emptyRow",
        "isValid": true,
        "hide": true,
        "keyCode": "none"
    },

    "hintYesNo": {
        "name": "hintYesNo",
        "type": "hintYesNo",
        "isValid": true,
        "hide": false,
        "keyCode": "none"
    },

    "testRadio": {
        "name": "Sind Sie ein überwiegend böser Mensch, der gerne mal einen Banküberfall durchführen würde?",
        "type": "radio",
        "required": true,
        "options":  [ "ja", "nein"],
        "useHelp": true,
        "helpText": "",
        "error": "Bitte zutreffendes anklicken",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none"
    },

    "firstName": {
        "name": "Vorname",
        "type": "text",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ausfüllen",
        "validate": [ {"atLeast":1}, {"max":255}],
        "isValid": true,
        "hide": false,
        "keyCode": "none"
    },

    "lastName": {
        "name": "Nachname",
        "type": "text",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ausfüllen",
        "validate": [ {"atLeast":1}, {"max":255}],
        "hide": false,
        "keyCode": "none"
    },

    "nameAffix": {
        "name": "Namenszusatz",
        "type": "selection",
        "required": false,
        "options": "nameAffix",
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "hide": false,
        "keyCode": "none",
    },

    "namePrefix": {
        "name": "Namensvorsatz",
        "type": "selection",
        "required": false,
        "options": "namePrefix",
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "hide": false,
        "keyCode": "none",
    },

    "title": {
        "name": "Titel",
        "type": "selection",
        "required": false,
        "options": "title",
        "useHelp": false,
        "helpText": "",
        "error": "",
        "validate": [],
        "hide": false,
        "keyCode": "none",
    },

    "explainEMail": {
        "name": "Hinweis E-Mail",
        "type": "explanation",
        "text": "Bitte geben Sie eine E-Mail-Adresse an, auf die nur Sie Zugriff haben. Wir nutzen diese E-Mail-Adresse, um Ihnen Ihre Abrechnung sicher und direkt zuzustellen.",
    },

    "eMail": {
        "name": "E-Mail",
        "type": "eMail",
        "required": false,
        "options": false,
        "useHelp": true,
        "helpText": "Ihre E-Mail-Adresse",
        "error": "Ungültige E-Mail Adresse",
        "validate": [{"eMail":"eMailOrEmpty"}],
        "hide": false,
        "keyCode": "none",
    },

    "explainPhoneNumber": {
        "name": "Hinweis Telefonnummer",
        "type": "explanation",
        "text": "Und gegebenenfalls auch Ihre Telefonnummer (Festnetz oder Mobil):",
    },

    "phoneNumber": {
        "name": "Telefon",
        "type": "text",
        "required": false,
        "options": false,
        "useHelp": false,
        "helpText": "Ihre Telefonnummer",
        "error": "Ungültige Email Adresse",
        "validate": [],
        "hide": false,
        "keyCode": "none",
    },

    "countryName": {
        "name": "Land",
        "type": "selectCountry",
        "required": true,
        "options": "countries",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ein Land auswählen.",
        "validate": [{"mustSelect":"mustSelect"}],
        "hide": false,
        "keyCode": "countryCode",
    },

    "countryCode": {
        "name": "PAISY Code",
        "type": "text",
        "required": true,
        "options": "countries",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ein Land auswählen.",
        "validate": [],
        "hide": true,
        "keyCode": "none",
    },

    "zipCode": {
        "name": "PLZ",
        "type": "text",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ausfüllen",
        "validate": [{"zip":"zip"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "location": {
        "name": "Ort",
        "type": "location",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "PLZ ungültig und/oder Ort fehlt.",
        "validate": [{"zip":"zip"}, {"atLeast":1}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "street": {
        "name": "Straße",
        "type": "street",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ausfüllen.",
        "validate": [{"atLeast":2}],
        "isValid": true,
        "hide": false,
    },

    "streetNumber": {
        "name": "Nummer",
        "type": "text",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ausfüllen.",
        "validate": ["atLeast1"],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "additionalAddressLine": {
        "name": "Anschriftenzusatz",
        "type": "text",
        "required": false,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ausfüllen",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "nationality": {
        "name": "Staatsangehörigkeit",
        "type": "selectNationality",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "nationalityCode",
    },

    "nationalityCode": {
        "name": "Nationalität",
        "type": "text",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [],
        "isValid": true,
        "hide": true,
        "keyCode": "none",
    },

    "gender": {
        "name": "Geschlecht",
        "type": "selection",
        "required": true,
        "options": "gender",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte passende Option auswählen.",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "genderCode",
    },

    "birthDate": {
        "name": "Geburtsdatum",
        "type": "date",
        "required": true,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [{"date":"date"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "birthName": {
        "name": "Geburtsname",
        "type": "textunknown",
        "checkBoxLabel" : "Das weiß ich nicht",
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte eingeben oder die Checkbox anklicken.",
        "validate": [{"textOrUnknown":"allowUnknown"}, {"atLeast":1}, {"max":255}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "birthNameAffix": {
        "name": "Zusatz Geburtsname",
        "type": "selection",
        "required": false,
        "options": "nameAffix",
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "hide": false,
        "keyCode": "none",
    },

    "birthNamePrefix": {
        "name": "Vorsatz Geburtsname",
        "type": "selection",
        "required": false,
        "options": "namePrefix",
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "hide": false,
        "keyCode": "none",
    },

    "birthLocation": {
        "name": "Geburtsort",
        "type": "textunknown",
        "checkBoxLabel" : "Das weiß ich nicht",
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [{"textOrUnknown":"allowUnknown"}, {"atLeast":1}, {"max":255}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "birthCountry": {
        "name": "Geburtsland",
        "type": "selectCountry",
        "required": true,
        "options": "countries",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte ein Land auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "hide": false,
        "keyCode": "birthCountryCode",
    },

    "IBAN": {
        "name": "IBAN (DE, AT, CH)",
        "type": "textunknown",
        "checkBoxLabel" : "Andere IBAN oder unbekannt.",
        "required": true,
        "options": [],
        "useHelp": true,
        "helpText": "IBAN",
        "error": "Ungültiger Wert",
        "validate": [{"IBAN":"de"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "BIC": {
        "name": "BIC",
        "type": "text",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "foreignIBAN": {
        "name": "Ausländische IBAN",
        "type": "text",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": ["IBAN"],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "differentAccountOwner": {
        "name": "Abweichender Kontoinhaber",
        "type": "text",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },


    "taxId": {
        "name": "Steuer-Identifikationsnummer",
        "type": "text",
        "required": true,
        "options": [],
        "useHelp": true,
        "helpText": "taxId",
        "error": "Ungültiger Wert",
        "validate": [ {"atLeast":11}, {"max":11}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "additionalOccupation": {
        "name": "Die Tätigkeit ist eine Nebenbeschäftigung",
        "type": "selection",
        "required": true,
        "options": ["additionalOccupation"],
        "useHelp": false,
        "helpText": "Wird der Arbeitnehmer mit Nebenbeschäftigung angemeldet, wird  immer die Steuerklasse VI von der Finanzverwaltung zurückgemeldet und zwar unabhängig davon, ob es einen anderen Arbeitgeber mit Hauptbeschäftigung gibt.",
        "error": "Ungültiger Wert",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "ELSTAMOcclusion": {
        "name": "ELSTAM Ausschluss",
        "type": "selection",
        "required": true,
        "options": "ELSTAMOcclusion",
        "useHelp": true,
        "helpText": "Personen, die vom ELStAM Verfahren ausgeschlossen werden sollen (z.B. Person ist mehrfach im System unter einer Arbeitgebersteuernummer angelegt). Personen die nicht am ELStAM Verfahren teilnehmen.",
        "error": "Ungültiger Wert",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "taxClass": {
        "name": "Steuerklasse der Tätigkeit",
        "type": "selection",
        "required": true,
        "options": ["taxClass"],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "taxClassContainer": {
        "name": "taxClassContainer",
        "type": "taxClassContainer"
    },

    "childrenTaxFreeFactor": {
        "name": "Kinderfreibetrag",
        "type": "text",
        "required": false,
        "options": false,
        "useHelp": false,
        "helpText": "Anzahl der Kinderfreibeträge lt. Lohnsteuerkarte für die Steuerklassen I bis IV.",
        "error": "Bitte passende Zahl eingeben",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "religiousDenomination": {
        "name": "Konfession",
        "type": "selection",
        "required": true,
        "options": ["religiousDenomination"],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "specialIncomeTaxTable": {
        "name": "Besondere Lohnsteuertabelle",
        "type": "selection",
        "required": true,
        "options": ["specialIncomeTaxTable"],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "officialOrPV": {
        "name": "Sind Sie Beamtin/er oder privatversichert?",
        "type": "selection",
        "required": true,
        "options": ["officialOrPV"],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "totalContributionPrivateHealthInsurance": {
        "name": "Gesamtbeitrag private KV",
        "type": "text",
        "required": false,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "startPensionPayment": {
        "name": "Datum erster Versorgungsbezug",
        "type": "date",
        "required": false,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [{"date":"date"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "ESLSTAMBirthDate": {
        "name": "ELSTAM Geburtsdatum",
        "type": "date",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [{"date":"date"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "maritalStatus": {
        "name": "Familienstand",
        "type": "selection",
        "required": false,
        "options": ["maritalStatus"],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "maritalStatusSince": {
        "name": "Familienstand seit",
        "type": "date",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [{"date":"date"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "SSN": {
        "name": "Sozialversicherungsnummer",
        "type": "textunknown",
        "text": "Da die Sozialversicherungsnummer nicht bekannt ist, oder Sie eine ausländische Nummer verwenden, bitte Folgendes angeben:",
        "checkBoxLabel" : "Das weiß ich nicht",
        "required": true,
        "options": [],
        "useHelp": true,
        "helpText": "SSN",
        "error": "Ungültiger Wert",
        "validate": ["SSN"],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "explainSSNother": {
        "name": "Erläuterung 2 zu SSN",
        "type": "explanation",
        "text": "Werden über diese Sozialversicherungsnummer bereits andere Beschäftigungsverhältnisse und/oder Rentenbezüge verbeitragt?",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "SSNother": {
        "name": "Short",
        "type": "selection",
        "required": true,
        "options": ["SSNother"],
        "useHelp": true,
        "helpText": "SSNother",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "healthInsuranceCompany": {
        "name": "Name der Krankenkasse",
        "type": "selection",
        "required": true,
        "options": "healthInsuranceCompany",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "healthInsuranceOperatingId",
    },

    "healthInsuranceCompanyPrivate": {
        "name": "Name der Beitragseinzugskasse",
        "type": "selection",
        "required": true,
        "options": "healthInsuranceCompany2",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "healthInsuranceOperatingIdPrivate",
    },

    "healthInsuranceOperatingId": {
        "name": "Betriebsnummer der Krankenkasse",
        "type": "text",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "healthInsuranceOperatingIdPrivate": {
        "name": "Betriebsnummer der Krankenkasse",
        "type": "text",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "healthInsurancePrivateCompanyName": {
        "name": "Name des Privatversicherers",
        "type": "text",
        "required": false,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "explainPrivateCompany": {
        "name": "Wenn privat versichert",
        "type": "explanation",
        "text": "Bitte senden Sie uns die Bestätigung Ihrer privaten Krankenversicherung über den Gesamtbeitrag (§ 257 SGB V und § 61 SGB XI).",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "explainPrivateCompany2": {
        "name": "Wenn privat versichert",
        "type": "explanation",
        "text": "Sofern für Sie Rentenversicherungsbeiträge abgeführt werden: bitte geben Sie hier an, an welche Krankenkasse die Rentenbeiträge gezahlt werden (das steht auf ihrem jährlichen Sozialversicherungsnachweis, sofern sie einen erhalten).",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "SSNContainer": {
        "name": "Social irgendwas",
        "type": "SSNContainer",
        "subTypes":[],
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "explainSSN": {
        "name": "Erläuterung zu SSN",
        "type": "explanation",
        "text": 'Wir benötigen Ihre Sozialversicherungsnummer (SVN). Wenn Sie diese Nummer nicht kennen und nicht herausfinden können oder ein ausländische Nummer verwenden, klicken Sie bitte auf "Nein".',
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "SSNisKnown": {
        "name": "SVN ist bekannt",
        "type": "radioButtonGroup",
        "subTypes":[],
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "hasPensionContribution": {
        "name": "",
        "type": "radioButtonGroup",
        "subTypes":[],
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "hasPensionContributionExplanation": {
        "name": "Erläuterung zu SSN",
        "type": "explanation",
        "text": 'Werden für Sie Rentenversicherungsbeiträge an die Deutsche Rentenversicherung abgeführt? Ja: z.B. weil ich Angestellte/r bin oder Nein: z.B. weil ich Rentner/in oder Beamter/Beamtin bin:',
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "hasPensionContributionExplanation2": {
        "name": "Erläuterung zu SSN",
        "type": "explanation",
        "text": 'Die Rentenversicherungsbeiträge werden auch bei privat Versicherten an eine gesetzliche Krankenkasse abgeführt. Diese finden Sie z.B. in Ihrer jährlichen Bescheinigung über die Sozialabgaben, sofern Sie einen Arbeitgeber haben. Bitte geben Sie hier diese Krankenkasse an:',
       "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "SSNAvailable": {
        "name": "SVN ist bekannt",
        "type": "radioButtonGroup",
        "subTypes":[],
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "BefreiungsbescheidDRV_P_C_B":{
        "name": "BefreiungsbescheidDRV",
        "type": "radioButtonGroup",
        "linkTo": "tree",
        "label": "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätigkeit?",
        "subTypes":[],
        "required": true,
        "options": [ 
            {id:"1", groupName:"yesNo", label:"Ja",value:"P_C_B_A"},
            {id:"0", groupName:"yesNo", label:"Nein",value:"P_C_B_B"},
        ],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "children": {
        "name": "Ich habe Kinder",
        "type": "radioButtonGroup",
        "subTypes":[],
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "childNumberUnder25": {
        "name": "Anzahl Kinder unter 25 Jahren",
        "type": "inputNumber",
        "subTypes":[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "childrenContainer": {
        "name": "Kinder",
        "type": "childrenContainer",
        "subTypes":[],
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "explainChildren": {
        "name": "Hinweis zu Kindern",
        "type": "explanation",
        "validate": [],
        "text": "Bitte geben Sie für die Berechnung des Pflegeversicherungsbeitrags an, ob Sie Kinder haben.",
    },

    "adminFirstName": {
        "name": "Vorname",
        "type": "text",
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [ {"atLeast":1}, {"max":255}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "adminLastName": {
        "name": "Nachname",
        "type": "text",
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [ {"atLeast":1}, {"max":255}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "adminUserName": {
        "name": "Username",
        "type": "text",
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [ {"atLeast":3}, {"max":255}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "adminInitialPWD": {
        "name": "Passwort (min 8)",
        "type": "text",
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [ {"pwd":"pwd"}],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "adminCompany": {
        "name": "Firma",
        "type": "selection",
        "required": true,
        "options": "companies",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "isValid": true,
        "hide": false,
        "keyCode": "groupsId",
    },

    "adminEMail": {
        "name": "EMail",
        "type": "eMail",
        "required": false,
        "options": false,
        "useHelp": false,
        "helpText": "",
        "error": "Ungültige Email Adresse",
        "validate": ["eMail"],
        "hide": false,
        "keyCode": "none",
    },

    "explainGraduation": {
        "name": "Hinweis Ausbildung",
        "type": "explanation",
        "validate": [],
        "text": "Diese Daten benötigen wir für statistische Zwecke der Sozialversicherung:",
    },

    "schoolGraduation": {
        "name": "Schulabschluss:",
        "type": "selection",
        "required": true,
        "options": "schoolGraduation",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "hide": false,
        "keyCode": "none",
    },

    "jobGraduation": {
        "name": "Ausbildungsabschluss",
        "type": "selection",
        "required": true,
        "options": "jobGraduation",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "hide": false,
        "keyCode": "none",
    },

    "healthInsuranceType": {
        "name": "Ich bin versichert",
        "type": "selection",
        "required": true,
        "options": "healthInsuranceType",
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [{"mustSelect":"mustSelect"}],
        "hide": false,
        "keyCode": "none",
    },

    "healthInsuranceContainer": {
        "name": "Art der Versicherung",
        "type": "healthInsuranceContainer",
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate":[],
        "hide": false,
        "keyCode": "none",
    },

    "Versorgungswerk": {
        "name": "Name d. Versorgungswerks",
        "type": "InputTreeText",
        "subTypes":[],
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },
    
    "VWMitgliednummer": {
        "name": "Mitgliedsnummer",
        "type": "InputTreeText",
        "subTypes":[],
        "required": false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Bitte auswählen",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "explainSendDocument": {
        "name": "Hinweis Documente",
        "type": "explanationDocument",
        "text": "Bitte schicken Sie uns entsprechende Unterlagen zu. (s. letzte Seite).",
    },

    "explainBefreiDRV": {
        "name": "Befreiung Beitrag DRV",
        "type": "explaintree",
        "text": "Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätgkeit bei unserem Unternehmen?",
    },

    "minijobIncomeMonth": {
        "name": "Betrag in € (ganze Zahl)",
        "type": "InputTreeText",
        "subTypes":[],
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "UeLeiterPauschaleMax":{
        "name": "Betrag in € (ganze Zahl)",
        "type": "InputTreeText",
        "subTypes":[],
        "required": true,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "UeLeiterHoursPerWeek": {
        "name": "Stunden pro Woche",
        "type": "InputTextTreeSmall",
        "subTypes":[],
        "required":false,
        "options": [],
        "useHelp": false,
        "helpText": "",
        "error": "Ungültiger Wert",
        "validate": [],
        "isValid": true,
        "hide": false,
        "keyCode": "none",
    },

    "UeLeiterHoursPerWeekExplanation": {
        "name": "Hinweis Ausbildung",
        "type": "explanation",
        "validate": [],
        "text": "Weitere Frage: Für die eventuelle Anwendung der Übungsleiterpauschale für die Nebentätigkeit geben Sie uns bitte an, wie viele Wochenstunden Sie in Ihrem Hauptberuf arbeiten (ohne Hauptberuf bitte 0 eintragen):",
    },


    "P": {
        label:"Meine berufliche Situation",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"Check67", 
                fieldName:"valid67", 
                label:"Check Altersgrenze",
                value:"", 
                validate:[{"date":"date"}], 
                error: "Ungültiger Wert",
                useHelp:false,},
        ],
        required: true,
        optionalText:"Bitte gehen Sie die folgende Liste von oben nach unten durch und kreuzen Sie die erste Zeile an, die auf Sie zutrifft, auch wenn danach noch weitere Zeilen auf Sie zutreffen würden.",
        useOptionalText:true,
        useHelp: false,
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        helpText: "",
        options: [
            {name:"Ich bin Beamtin/er (aktiv oder in Pension).", value:"P_A"},
            {name:"Ich bin Rentner/in (nicht gemeint: Hinterbliebenenrente).", value:"P_B"},
            {name:"Ich habe einen Hauptberuf neben der Tätigkeit bei unserem Unternehmen.", value:"P_C"},
            {name:"Ich bin Student/in.", value:"P_D"},
            {name:"Ich habe sonst keine Beschäftigung.", value:"P_E"}
        ],
    },

    "P_A": {
        label:"Ich bin pensionierte(r) Beamtin/er.",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: true,
        optionalText:"",
        useOptionalText:"",
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Ja, ich bin in Pension.", value:"P_A_A"},
            {name:"Nein, in bin noch aktive/r Beamtin/er.", value:"P_A_B"}
        ],
    },

    "P_B": {
        label:"Ich bin Rentner/in",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: true,
        optionalText:"",
        useOptionalText:"",
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Normalfall: Ich beziehe die gesamte, mir zustehende Rente (ggf. mit Abschlag bei vorzeitigem Bezug) oder Versorgungsbezüge.", value:"P_B_A"},
            {name:"Sonderfall: Teilrente: ich habe meine Rentenversicherung bzw. mein Versorgungswerk gebeten, nur einen Teil der Rente auszuzahlen, damit ich später mehr Rente bekomme.", value:"P_B_B"},
            {name:"Ich beziehe eine Erwerbsminderungsrente (volle Erwerbsminderung).", value:"P_B_C"},
            {name:"Ich beziehe eine Berufsunfähigkeitsrente (teilweise Erwerbsminderung).", value:"P_B_D"},
            {name:"Ich bin im Rentenalter, erhalte jedoch keine Rente.", value:"P_B_E"},
        
        ],
    },
    "P_B_A": {
        label:"Rente - Normalfall",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"InputTextTree", 
                fieldName:"Versorgungswerk", 
                label:"Versorgungswerk",
                value:"", 
                useHelp:false
            },
            {
                type:"InputDateTree", 
                fieldName:"pensionStart", 
                label:"Pension Start",
                value:"", 
                useHelp:false}
        ],
        required: true,
        optionalText:"Wo sind Sie versichert?",
        useOptionalText:true,
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Ich bin bei der Deutschen Rentenversicherung versichert.", value:"P_B_A_A"},
            {name:"In einem Versorgungswerk (z.B. für Ärzte, Anwälte etc.).", value:"P_B_A_B"},
        ],
    },

    "P_B_A_A": {
        label:"Ja, ich bin bei der Deutschen Rentenversicherung versichert.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Bitte senden Sie uns nach Möglichkeit ihren Rentenbescheid, damit wir die richtige Form der Sozialversicherung prüfen können.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_B_A_B": {
        label:"Ich bin in einem Versorgungswerk versichert.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"Versorgungswerk", 
                fieldName:"Versorgungswerk", 
                label:"Name des Versorgungswerks",
                value:"", 
                useHelp:false
            },
            {
                type:"VWMitgliednummer", 
                fieldName:"VWMitgliednummer", 
                label:"Meine Mitgliedsnummer",
                value:"", 
                useHelp:false
            },
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_B_B": {
        label:"Sonderfall Teilrente",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"InputTextTree", 
                fieldName:"Versorgungswerk", 
                label:"SchubidDu",
                value:"", 
                useHelp:false
            },
            {
                type:"InputDateTree", 
                fieldName:"pensionStart", 
                label:"SchubidDu Date",
                value:"", 
                useHelp:false}
        ],
        required: true,
        optionalText:"Wo sind Sie versichert?",
        useOptionalText:true,
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Ich bin bei der Deutschen Rentenversicherung versichert.", value:"P_B_B_A"},
            {name:"In einem Versorgungswerk (z.B. für Ärzte, Anwälte etc.).", value:"P_B_B_B"},
        ],
    },

    "P_B_B_A": {
        label:"Ja, ich bin bei der Deutschen Rentenversicherung versichert.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Bitte senden Sie uns nach Möglichkeit ihren Rentenbescheid, damit wir die richtige Form der Sozialversicherung prüfen können.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_B_B_B": {
        label:"Ich bin in einem Versorgungswerk versichert.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"Versorgungswerk", 
                fieldName:"Versorgungswerk", 
                label:"Name des Versorgungswerks",
                value:"", 
                useHelp:false
            },
            {
                type:"VWMitgliednummer", 
                fieldName:"VWMitgliednummer", 
                label:"Meine Mitgliednummer",
                value:"", 
                useHelp:false
            },
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_B_C": {
        label: "Ich beziehe eine Erwerbsminderungsrente (volle Erwerbsminderung).",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            
            {
                type:"InputTextTree", 
                fieldName:"Versorgungswerk", 
                label:"not label",
                value:"", 
                useHelp:false
            },
            {
                type:"InputDateTree", 
                fieldName:"pensionStart", 
                label:"no label date",
                value:"", 
                useHelp:false}
        ],
        required: true,
        optionalText:"Wo sind Sie versichert?",
        useOptionalText:true,
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Ich bin bei der Deutschen Rentenversicherung versichert.", value:"P_B_C_A"},
            {name:"In einem Versorgungswerk (z.B. für Ärzte, Anwälte etc.).", value:"P_B_C_B"},
        ],
    },

    "P_B_C_A": {
        label:"Ja, ich bin bei der Deutschen Rentenversicherung versichert.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Bitte senden Sie uns nach Möglichkeit ihren Rentenbescheid, damit wir die richtige Form der Sozialversicherung prüfen können.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_B_C_B": {
        label:"Ich bin in einem Versorgungswerk versichert.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"Versorgungswerk", 
                fieldName:"Versorgungswerk", 
                label:"Name des Versorgungswerks",
                value:"", 
                useHelp:false
            },
            {
                type:"VWMitgliednummer", 
                fieldName:"VWMitgliednummer", 
                label:"Meine Mitgliednummer",
                value:"", 
                useHelp:false
            },
            {
                type:"explainBefreiDRV", 
                fieldName:"explainBefreiDRV", 
            },
        ],
        required: false,
        optionalText:"Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_B_D": {
        label:"Ich beziehe eine Berufsunfähigkeitsrente (teilweise Erwerbsminderung).",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"InputTextTree", 
                fieldName:"Versorgungswerk", 
                label:"",
                value:"", 
                useHelp:false
            },
            {
                type:"InputDateTree", 
                fieldName:"pensionStart", 
                label:"",
                value:"", 
                useHelp:false}
        ],
        required: true,
        optionalText:"Wo sind Sie versichert?",
        useOptionalText:true,
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Ich bin bei der Deutschen Rentenversicherung versichert", value:"P_B_D_A"},
            {name:"In einem Versorgungswerk (z.B. für Ärzte, Anwälte etc.)", value:"P_B_D_B"},
        ],
    },

    "P_B_D_A": {
        label:"Ja, ich bin bei der Deutschen Rentenversicherung versichert.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Bitte senden Sie uns nach Möglichkeit ihren Rentenbescheid, damit wir die richtige Form der Sozialversicherung prüfen können.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_B_D_B": {
        label:"Ich bin in einem Versorgungswerk versichert.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"Versorgungswerk", 
                fieldName:"Versorgungswerk", 
                label:"Name des Versorgungswerks",
                value:"", 
                useHelp:false
            },
            {
                type:"VWMitgliednummer", 
                fieldName:"VWMitgliednummer", 
                label:"Meine Mitgliednummer",
                value:"", 
                useHelp:false
            },
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_B_E": {
        label:"Ich bin im Rentenalter, erhalte jedoch keine Rente.",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: true,
        optionalText:"",
        useOptionalText:false,
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Ich habe keinen Rentenanspruch", value:"P_B_E_A"},
            {name:"Ich erfüllte die  Voraussetzungen, nehme aber keine Rente in Anspruch.", value:"P_B_E_B"},
        ],
    },





    "P_C": {
        label:"Hauptberuf neben der Tätigkeit beim Unternehmen",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: true,
        optionalText:"Wie sind Sie versichert?",
        useOptionalText:true,
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Ich bin bei der Deutschen Rentenversicherung versichert.", value:"P_C_A"},
            {name:"Ich bin in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert Bitte geben Sie hier das Versorgungswerk an:", value:"P_C_B"},
            {name:"Ich bin nicht rentenversicherungspflichtig (z.B. hauptberuflich selbständig).", value:"P_C_C"},
        ],
    },

    "x_P_C_A": {
        label:"Umfang des Hauptberufs",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"UeLeiterHoursPerWeek", 
                fieldName:"UeLeiterHoursPerWeek", 
            },
        ],
        required: false,
        optionalText:"Weitere Frage: Für die eventuelle Anwendung der Übungsleiterpauschale für die Nebentätigkeit geben Sie uns bitte an, wie viele Wochenstunden Sie in Ihrem Hauptberuf (ohne Hauptberuf bitte 0 eintragen):",
        useOptionalText:true,
        useHelp: false,
        helpText: "",
        validate: [],
        error: "",
        options: [],
    },

    "P_C_B": {
        label:"Versorgungswerk",
        linkTo: "tree",
        type: "tree",
        subTypes:[

            {
                type:"Versorgungswerk", 
                fieldName:"Versorgungswerk", 
                label:"Name des Versorgungswerks",
                value:"", 
                useHelp:false
            },
            {
                type:"VWMitgliednummer", 
                fieldName:"VWMitgliednummer", 
                label:"Meine Mitgliednummer",
                value:"", 
                useHelp:false
            },

            {
                type:"BefreiungsbescheidDRV_P_C_B", 
                fieldName:"BefreiungsbescheidDRV_P_C_B", 
                label:"Haben Sie einen Befreiungsbescheid der Deutschen Rentenversicherung für Ihre Tätgkeit beim unserem Unternehmen?",
                value:"", 
                useHelp:false
            },
        ],
        required: false,
        optionalText:"Sie haben angegeben, in einem Versorgungswerk und nicht in der Deutschen Rentenversicherung versichert zu sein. Bitte geben Sie hier das Versorgungswerk an:",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_C_B_A": {
        label:"P_C_B_A_Label",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: false,
        optionalText:"Should not be seen.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_C_B_B": {
        label:"P_C_B_B Label",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: false,
        optionalText:"Should not be seen.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_C_C": {
        label:"Nicht rentenversicherungspflichtig",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: false,
        optionalText:"",
        useOptionalText:false,
        useHelp: false,
        helpText: "",
        validate: false,
        error: "",
        options: [],
    },


    "P_D": {
        label:"Ich habe eine gültige Immatrikulationsbescheinigung.",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: true,
        optionalText:"",
        useOptionalText:"",
        useHelp: false,
        helpText: "",
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        options: [
            {name:"Ja", value:"P_D_A"},
            {name:"Nein", value:"P_D_B"},
        ],
    },

    "P_D_A": {
        label:"Immatrikulationsbescheinigung zusenden.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"",
        useOptionalText:false,
        useHelp: false,
        helpText: "",
        validate: [],
        error: "Bitte auswählen",
        options: [],
    },



    "P_A_A": {
        label:"Meine Pension",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: true,
        optionalText:"Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit bei unserem Unternehmen erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
        useOptionalText:true,
        useHelp: false,
        validate: [ {"mustSelect":"mustSelect"}],
        helpText: "",
        error: "Bitte auswählen",
        options: [
            {name:"Ja, ich habe den Bescheid", value:"P_A_A_A"},
            {name:"Nein", value:"P_A_A_B"}
        ],
    },

    "P_A_A_A": {
        label:"Der Bescheid ist vorhanden. Bitte zusenden.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],

        required: false,
        optionalText:"",
        useOptionalText:false,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_A_A_B": {
        label:"Bescheid noch nicht vorhanden. Wenn möglich, bitte nachreichen.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: true,
        optionalText:"Es wäre für uns von Vorteil, wenn Sie solch einen Bescheid beantragen könnten. Wir helfen Ihnen gern dabei.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_A_B": {
        label:"Meine Pension",
        linkTo: "tree",
        type: "tree",
        subTypes:[],
        required: true,
        optionalText:"Ich habe einen Bescheid meines Dienstherren, dass sich meine Versorgungsbezüge (Pension) auch auf meine Nebentätigkeit bei unserem Unternehmen erstrecken (Gewährleistungserstreckungsbescheid nach §5 SGB VI).",
        useOptionalText:true,
        useHelp: false,
        validate: [{"mustSelect":"mustSelect"}],
        helpText: "",
        error: "Bitte auswählen",
        options: [
            {name:"Ja", value:"P_A_B_A"},
            {name:"Nein", value:"P_A_B_B"}
        ],
    },

    "P_A_B_A": {
        label:"Der Bescheid ist vorhanden. Bitte zusenden.",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Den Bescheid bitte per E-Mail senden.",
        useOptionalText:false,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },

    "P_A_B_B": {
        label:"Bescheid nicht vorhanden",
        linkTo: "tree",
        type: "tree",
        subTypes:[
            {
                type:"explainDocs", 
                fieldName:"explainSendDocument", 
            },
        ],
        required: false,
        optionalText:"Es wäre für uns von Vorteil, wenn Sie solch einen Bescheid beantragen könnten. Wir helfen Ihnen gern dabei.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        helpText: "",
        error: "",
        options: [],
    },



    "Mini": {
        label:"Ich habe einen Minijob (in einem anderen Unternehmen).",
        type: "Mini",
        linkTo: "Mini",
        subTypes:[],
        required: true,
        optionalText:"",
        useOptionalText:false,
        useHelp: false,
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        helpText: "",
        options: [
            {name:"Ja", value:"Mini_A"},
            {name:"Nein", value:"Mini_B"},
        ],
    },

    "Mini_A": {
        label:"Haben Sie zusätzlich zum Minijob auch eine Beschäftigung, bei der Sie sozialversicherungspflichtig beschäftigt sind (also für mehr als 538 € im Monatsmittel)?",
        type: "Mini",
        linkTo: "Mini",
        subTypes:[],
        required: true,
        optionalText:"Hinweis: wenn Sie nur als Beamter/Beamtin tätig sind, bitte „Nein“ ankreuzen, da Beamte nicht sozialversicherungspflichtig beschäftigt sind.",
        useOptionalText:true,
        useHelp: false,
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        helpText: "",
        options: [
            {name:"Ja", value:"Mini_A_A"},
            {name:"Nein", value:"Mini_A_B"},
        ],
    },

    "Mini_A_A": {
        label:"Ja.",
        type: "Mini",
        linkTo: "Mini",
        subTypes:[],
        required: false,
        optionalText:"In diesem Fall ist nur ein Minijob möglich, den haben Sie schon. Wir finden einen anderen Weg, der nicht analog zu einem Minijob ist.",
        useOptionalText:true,
        useHelp: false,
        validate: [],
        error: "Bitte auswählen",
        helpText: "",
        options: [
        ],
    },
    
    "Mini_A_B": {
        label:"Nein.",
        type: "Mini",
        linkTo: "Mini",
        subTypes:[
            {
                type:"minijobIncomeMonth", 
                fieldName:"minijobIncomeMonth", 
            },
        ],
        required: true,
        optionalText:"Geben Sie bitte das monatliche Einkommen ihres Minijobs an, damit wir prüfen können, ob die Tätigkeit bei uns auch noch als Minijob möglich ist.",
        useOptionalText:true,
        useHelp: false,
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        helpText: "",
        options: [
        ],
    },

    "old_Mini_B": {
        label:"Kein Minijob",
        type: "Mini",
        linkTo: "Mini",
        subTypes:[],
        required: true,
        optionalText:"NUR FALLS ANTWRT OBEN: Ich habe einen Hauptberuf UND DORT FALL 1 ausgewählt: TEXT: Danke, dann finden wir einen anderen Weg, der nicht analog zu einem Minijob ist.",
        useOptionalText:true,
        useHelp: true,
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        helpText: "",
        options: [],
    },

    "AddQ": {
        label:"Dozententätigkeit",
        type: "AddQ",
        linkTo: "AddQ",
        subTypes:[],
        required: true,
        optionalText:"Unser Unternehmen kann die Übungsleiterpauschale (bis max. 3000 € pro Jahr) nutzen, damit Honorare aus Dozententätigkeit oder Korrekturen (nicht aber Studienortleitung, Prüfungsbetreuung etc.) von der Sozialversicherung und der Abführung von Lohnsteuer befreit sind. Uns wurde empfohlen, dies erst ab Januar 2025 zu nutzen, um eine korrekte Abwicklung sicherzustellen, die bei einem innerjährlichen Wechsel schwieriger ist. Möchten Sie, soweit sie als Dozent tätig sind, uns bitten, für Unterrichtshonorare ab 2025 soweit möglich die Übungsleiterpauschale zu nutzen?",
        useOptionalText:true,
        useHelp: false,
        validate: [{"mustSelect":"mustSelect"}],
        error: "Bitte auswählen",
        helpText: "",
        options: [
            {name:"Ja, ich möchte ab 2025 die Übungsleiterpauschale in voller Höhe dem Unternehmen zur Verfügung stellen, um meine Honore soweit möglich von Sozialabgaben und Steuern zu befreien.", value:"AddQ_A"},
            {name:"Ja, bis zu einem maximalen Betrag für 2025, weil ich den übrigen Betrag für andere Übungsleitertätigkeiten nutze.", value:"AddQ_B"},
            {name:"Nein, z.B. weil ich die Übungsleiterpauschale für andere Tätigkeiten nutze.", value:"AddQ_C"},
        ],
    },

    "AddQ_B": {
        label:"Maximaler Betrag für 2025",
        type: "AddQ",
        linkTo: "AddQ",
        subTypes:[
            {
                type:"UeLeiterPauschaleMax", 
                fieldName:"UeLeiterPauschaleMax", 
            },
        ],
        required: true,
        optionalText: "",
        useOptionalText:false,
        useHelp: false,
        validate: [],
        error: "Bitte auswählen",
        helpText: "",
        options: [],
    },



})
